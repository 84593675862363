import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import "./SavedSearch.css";
import BroadcastPopup from "./BroadcastPopup";
import { broadCastListApi } from '../../components/Api/ListingApi';
import Moment from "moment-timezone";
import Pagination from '../../components/Pagination';

var currentPage = 1;
export default function BroadcastList(props) {
    const { broadcastList, toggleBroadCastList, editDetails } = props;
    const [broadcastAgent, setBroadcastAgent] = useState(false)
    const [broadcastData, setBroadcastData] = useState([])
    const [broadcastDetails, setBroadcastDetails] = useState({})
    const [pagecount, setpagecount] = useState(0);
    const [selectedPage, setSelectedPage] = useState(0);
    const toggleBroadcast = () => {
        setBroadcastAgent(p => !p)
    }
    useEffect(() => {
        BroadcastListing()
    }, [])

    const BroadcastListing = async () => {
        let userId = localStorage.getItem("userId")
        let getParams = {
            agent_id: userId,
            page: currentPage,
            pagesize: 20,
            // type:"buy",
            save_search_id: editDetails._id
        }
        await broadCastListApi(getParams).then((res) => {
            // console.log("res list", res.data.data)
            setBroadcastData(res.data.data)
            setpagecount(res.data.Paging.total_page);
            setSelectedPage(res.data.Paging.current_page - 1);
        })
    }
    // pagination api integration //
    async function handlePageClick(event) {
        currentPage = event.selected + 1;
        await BroadcastListing();
    };
    // console.log("broadcastData", editDetails)


    return (
        <div>
            {broadcastAgent && <BroadcastPopup broadcastAgent={broadcastAgent} toggleBroadcast={toggleBroadcast} editDetails={editDetails} broadcastDetails={broadcastDetails} refresh={BroadcastListing} />}
            <Modal size="lg" show={broadcastList} onHide={toggleBroadCastList} className="modelparent broadcast-popup" style={{ top: "10%", height: "850px" }}>
                <div className="PopUpBorderDiv closeIcons" style={{ border: "0px" }}>
                    <Modal.Header closeButton className="team-popup-margin1 teampopup-title savedSearchshare">
                        <Modal.Title className="savedSearch">
                            Select agent(s) you want to broadcast<br />{`"${editDetails.name}"`}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="team-popup-margin1 pb-0 broadcastWidth" >
                        <div>
                            <div className='position-relative savedSearchinput1 mb-3' style={{ width: "100%", margin: "0px", padding: "0.5rem 0re", height: "700px", overflow: "auto" }}>
                                <div className='d-flex justify-content-end'>
                                    <button className='broadcastListBut' onClick={() => { toggleBroadcast(); setBroadcastDetails({}) }}>New Broadcast</button>
                                </div>
                                <div className='row mt-3' style={{ backgroundColor: "#f3f0f0", margin: "0px", paddingTop: "10px", paddingBottom: "10px" }}>
                                    <div className='col-md-3'>
                                        <h5 className=' savedSearch' style={{ color: "black", height: "100%", display: "flex", alignItems: "center" }}>Send to</h5>
                                    </div>
                                    <div className='col-md-3'>
                                        <h5 className=' savedSearch' style={{ color: "black", height: "100%", display: "flex", alignItems: "center" }}>Date Sent</h5>
                                    </div>
                                    <div className='col-md-6'>
                                        <h5 className=' savedSearch' style={{ color: "black", height: "100%", display: "flex", justifyContent: "center", textAlign: "center" }}>Actions</h5>
                                    </div>
                                </div>
                                {broadcastData !== 0 ? broadcastData.map((item, i) => (
                                    <div className='row mt-4' style={{
                                        margin: "0px", alignItems: "baseline",
                                        paddingBottom: "15px",
                                        borderBottom: "1px solid rgb(207 207 207)"
                                    }}>
                                        <div className='col-md-3'>
                                            <p className='broadcastListName text-capitalize'>{item.broadcast_type}</p>
                                        </div>
                                        <div className='col-md-3'>
                                            <p className='broadcastListName'>{Moment(item.createdAt).format("MM-D-yyyy")}</p>
                                        </div>
                                        <div className='col-md-6 d-flex justify-content-center' style={{ padding: "0px" }}>
                                            <button className='resendButList' onClick={() => { toggleBroadcast(); setBroadcastDetails(item) }}>Resend</button>
                                            {/* <button className='resendButList' >View Report</button> */}
                                        </div>
                                    </div>
                                )) : null}
                                <div className='resulttext'>
                                    {broadcastData.length == 0 && <div className='no-resultSell mt-5'> Result not found</div>}
                                </div>

                            </div>
                            <div>
                                {(broadcastData.length !== 0 && broadcastData.length !== 1) ? <Pagination pagecount={pagecount} handlePageClick={handlePageClick} forcepage={selectedPage} /> : null}</div>
                        </div>
                    </Modal.Body>
                </div>
            </Modal >
        </div >
    );
}
